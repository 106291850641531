.embla {
  margin: auto;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  position: relative;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.embla__controls {
  max-width: 1040px;
  padding-top: 40px;
}


.embla__buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: calc(100% + 160px);
  left: -80px;
  top: 30%;
}

@media screen and (max-width: 1199px) {
  .embla__buttons {
    display: none;
  }
}

.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 160px;
  height: 160px;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .75;
  transition: opacity 0.2s ease-in-out;
}

.embla__button:hover {
  opacity: 1;
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
}

.embla__button__svg {
  width: 35%;
  height: 35%;
}

.embla__dots {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding-inline: 20px;
}

@media screen and (min-width: 1200px) {
  .embla__dots {
    display: none;
  }
}

.embla__dot {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 200px;
  height: 8px;
  border-radius: 8px;
  border: 2px solid white;
  transition: opacity 0.2s ease-in-out;
  opacity: .5;
  mix-blend-mode: screen;
}

.embla__dot:hover {
  background-color: white;
  border: 2px solid white;
  opacity: 1;
}

.embla__dot--selected {
  background-color: transparent;
  border: 2px solid white;
  opacity: 1;
}
